import { forwardRef, HTMLAttributes, Ref } from 'react'
import { SPACING } from 'shared/theme'
import styled, { css, IStyledComponent } from 'styled-components'

import { checkFlexGapSupport } from '../../helpers/checkFlexGapSupport'
import { AddPrefixToObject } from '../../helpers/typeHelper'
import { containerQuery } from '../../helpers/utils'
import { breakpoints } from '../../theme/breakpoints'

const isFlexBoxGapSupported = checkFlexGapSupport()

type FlexGapUnit = 'px' | '%'
type FlexGapAmount = number
type FlexGapValue = `${FlexGapAmount}${FlexGapUnit}`

export interface FlexBoxProps {
  isColumn?: boolean
  flex?: number
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
  smAlignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
  xsAlignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly' | 'space-around'
  smJustifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly' | 'space-around'
  flexDirection?: 'column' | 'row' | 'column-reverse' | 'row-reverse'
  smFlexDirection?: 'column' | 'row' | 'column-reverse' | 'row-reverse'
  gap?: FlexGapValue
  smGap?: FlexGapValue
  styles?: string
  color?: string
  width?: string
  minWidth?: string
  isFullWidth?: boolean
  isFullHeight?: boolean
  mt?: number
  mb?: number
  ml?: number
  mr?: number
}

export const flexGapPolyfill = (gap: FlexGapValue) => {
  if (isFlexBoxGapSupported) return `gap: ${gap};`

  const gapUnit = gap.replace(/\d/gi, '')
  const gapAmount = Number(gap.replace(gapUnit, ''))

  return css`
    gap: 0;
    margin: ${gapAmount / -2}${gapUnit} ${gapAmount / -2}${gapUnit} !important;

    > * {
      margin: ${gapAmount / 2}${gapUnit} ${gapAmount / 2}${gapUnit} !important;
    }
  `
}

const StyledFlexBox = styled.div<AddPrefixToObject<FlexBoxProps, '$'>>`
  display: flex;

  ${(p) =>
    p.$isColumn &&
    css`
      flex-direction: column;
    `};

  ${(p) =>
    p.$flex &&
    css`
      flex: ${p.$flex};
    `};

  ${(p) =>
    p.$flexDirection &&
    css`
      flex-direction: ${p.$flexDirection};
    `};

  ${(p) =>
    p.$alignItems &&
    css`
      align-items: ${p.$alignItems};
    `};

  ${(p) =>
    p.$width &&
    css`
      width: ${p.$width};
    `};

  ${(p) =>
    p.$minWidth &&
    css`
      min-width: ${p.$minWidth};
    `};

  ${(p) =>
    p.$isFullWidth &&
    css`
      width: 100%;
    `};

  ${(p) =>
    p.$isFullHeight &&
    css`
      height: 100%;
    `};

  ${(p) =>
    p.$justifyContent &&
    css`
      justify-content: ${p.$justifyContent};
    `};

  ${(p) => p.$gap && flexGapPolyfill(p.$gap)};

  ${(p) =>
    p.$color &&
    css`
      color: ${p.$color};
    `};

  ${(p) =>
    p.$mt &&
    css`
      margin-top: ${SPACING * p.$mt}px;
    `};

  ${(p) =>
    p.$mb &&
    css`
      margin-bottom: ${SPACING * p.$mb}px;
    `};

  ${(p) =>
    p.$ml &&
    css`
      margin-left: ${SPACING * p.$ml}px;
    `};

  ${(p) =>
    p.$mr &&
    css`
      margin-right: ${SPACING * p.$mr}px;
    `};

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `};

  ${(p) => p.$smGap && containerQuery(breakpoints.sm, flexGapPolyfill(p.$smGap))}
  ${(p) =>
    p.$smFlexDirection &&
    containerQuery(
      breakpoints.sm,
      css`
        flex-direction: ${p.$smFlexDirection};
      `
    )}
  ${(p) =>
    p.$smJustifyContent &&
    containerQuery(
      breakpoints.sm,
      css`
        justify-content: ${p.$smJustifyContent};
      `
    )}
  ${(p) =>
    p.$smAlignItems &&
    containerQuery(
      breakpoints.sm,
      css`
        align-items: ${p.$smAlignItems};
      `
    )}

  ${(p) =>
    p.$xsAlignItems &&
    containerQuery(
      breakpoints.xs,
      css`
        align-items: ${p.$xsAlignItems};
      `
    )}

  ${(p) => p.$styles};
`

export const FlexBox = forwardRef(
  (
    {
      isColumn,
      flex,
      alignItems,
      smAlignItems,
      xsAlignItems,
      justifyContent,
      smJustifyContent,
      flexDirection,
      smFlexDirection,
      gap,
      smGap,
      styles,
      color,
      width,
      minWidth,
      isFullWidth,
      isFullHeight,
      mt,
      mb,
      mr,
      ml,
      ...rest
    },
    ref: Ref<any>
  ) => {
    return (
      <StyledFlexBox
        $isColumn={isColumn}
        $flex={flex}
        $alignItems={alignItems}
        $smAlignItems={smAlignItems}
        $xsAlignItems={xsAlignItems}
        $justifyContent={justifyContent}
        $smJustifyContent={smJustifyContent}
        $flexDirection={flexDirection}
        $smFlexDirection={smFlexDirection}
        $gap={gap}
        $smGap={smGap}
        $styles={styles}
        $color={color}
        $width={width}
        $minWidth={minWidth}
        $isFullWidth={isFullWidth}
        $isFullHeight={isFullHeight}
        $mt={mt}
        $mb={mb}
        $ml={ml}
        $mr={mr}
        ref={ref}
        {...rest}
      />
    )
  }
) as IStyledComponent<'web', FlexBoxProps & HTMLAttributes<HTMLDivElement> & { ref?: Ref<any> }>
